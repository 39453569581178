
import Vue from "vue";
import { mapGetters } from "vuex";
//@ts-ignore
import Password from "vue-password-strength-meter";

export default Vue.extend({
  name: "SignUpFormMentor",

  components: {
    Password
  },
  data() {
    return {
      data: {
        first_name: "",
        last_name: "",
        password: "",
        password_confirmation: "",
        type: "mentor"
      },
      i_agree: false,
      errors: {},
      btnLoading: false,
      passwordFieldType: "password"
    };
  },
  computed: {
    ...mapGetters({
      application: "mentor_application/getApplication",
      termsAndConditionLink: "helper/getTermsAndConditionLink"
    })
  },
  mounted() {
    if (!this.application.id) {
      this.$router.push({
        name: "SignUpPageMentor"
      });
    }
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onRegister() {
      this.btnLoading = true;

      this.$store
        .dispatch("auth/REGISTER_USER", {
          ...this.data,
          email: this.application.email
        })
        .then(() => {
          this.btnLoading = false;
          // @ts-ignore
          this.$alertify.success("Register Successfully");
          this.$router.push({
            name: "SignUpProfileFillPage"
          });
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
